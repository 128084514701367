import * as React from "react"
import styled from "@emotion/styled"

type Props = {}

type State = {}

export default class TermsOfService extends React.Component<Props, State> {
  render() {
    return (
      <div style={{ padding: 100 }}>
        <div>
          <h1>Prowess End User Terms of Service</h1>
          <div style={{ paddingBottom: 40 }}>Last updated May 10, 2020</div>
          <p>
            These Terms of Use constitute a legally binding agreement made
            between you, whether personally or on behalf of an entity (“you”)
            and LANGAPI COMPANY, doing business as Prowess ("Prowess", “we”,
            “us”, or “our”), concerning your access to and use of the
            http://www.prowess.app website as well as any other media form,
            media channel, mobile website or mobile application related, linked,
            or otherwise connected thereto (collectively, the “Site”). You agree
            that by accessing the Site, you have read, understood, and agree to
            be bound by all of these Terms of Use. IF YOU DO NOT AGREE WITH ALL
            OF THESE TERMS OF USE, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING
            THE SITE AND YOU MUST DISCONTINUE USE IMMEDIATELY.
          </p>
          <p>
            Supplemental terms and conditions or documents that may be posted on
            the Site from time to time are hereby expressly incorporated herein
            by reference. We reserve the right, in our sole discretion, to make
            changes or modifications to these Terms of Use at any time and for
            any reason. We will alert you about any changes by updating the
            “Last updated” date of these Terms of Use, and you waive any right
            to receive specific notice of each such change. It is your
            responsibility to periodically review these Terms of Use to stay
            informed of updates. You will be subject to, and will be deemed to
            have been made aware of and to have accepted, the changes in any
            revised Terms of Use by your continued use of the Site after the
            date such revised Terms of Use are posted.
          </p>
          <p>
            The information provided on the Site is not intended for
            distribution to or use by any person or entity in any jurisdiction
            or country where such distribution or use would be contrary to law
            or regulation or which would subject us to any registration
            requirement within such jurisdiction or country. Accordingly, those
            persons who choose to access the Site from other locations do so on
            their own initiative and are solely responsible for compliance with
            local laws, if and to the extent local laws are applicable.
          </p>
          <p>
            The Site is not tailored to comply with industry-specific
            regulations (Health Insurance Portability and Accountability Act
            (HIPAA), Federal Information Security Management Act (FISMA), etc.),
            so if your interactions would be subjected to such laws, you may not
            use this Site. You may not use the Site in a way that would violate
            the Gramm-Leach-Bliley Act (GLBA).
          </p>
          <p>
            The Site is intended for users who are at least 18 years old.
            Persons under the age of 18 are not permitted to use or register for
            the Site.
          </p>
          <p>
            By accepting these Terms, or by accessing or using the Service or
            Website, or authorizing or permitting any User or End-User to access
            or use the Service, You agree to be bound by these Terms. If You are
            entering into these Terms on behalf of a company, organization or
            another legal entity (an “Entity”), You are agreeing to these Terms
            for that Entity and representing to Prowess that You have the
            authority to bind such Entity and its affiliates to these Terms, in
            which case the terms “Subscriber,” “You,” “Your” or related
            capitalized terms herein shall refer to such Entity and its
            affiliates. If You do not have such authority, or if You do not
            agree with these Terms, You must not accept these Terms and may not
            use the Service.
          </p>
          <h1>1. DEFINITIONS</h1>
          <div>
            When used in these Terms with the initial letters capitalized, in
            addition to terms defined elsewhere in these Terms, the following
            terms have the following meanings: Account: means all Prowess
            accounts or instances created by or on behalf of Subscriber within
            the Service.
          </div>
          <div>
            Beta Services: means a product, service or functionality provided by
            Prowess that may be made available to You to try at Your option at
            no additional charge which is clearly designated as beta, pilot,
            limited release, non-production, early access, evaluation, labs or
            by a similar description.
          </div>
          <p>
            Confidential Information: means all information disclosed by You to
            Prowess or by Prowess to You which is in tangible form and labeled
            “confidential” (or with a similar legend) or which a reasonable
            person would understand to be confidential given the nature of the
            information and circumstances of disclosure. For purposes of these
            Terms, Your Data shall be deemed Confidential Information.
            Notwithstanding the foregoing, Confidential Information shall not
            include information that (a) was already known to the receiving
            party at the time of disclosure by the disclosing party; (b) was or
            is obtained by the receiving party by a third party not known by the
            receiving party to be under an obligation of confidentiality with
            respect to such information; (c) is or becomes generally available
            to the public other than by violation of these Terms; or (d) was or
            is independently developed by the receiving party without use of the
            disclosing party’s Confidential Information.
          </p>
          <p>
            Documentation: means any written or electronic documentation,
            images, video, text or sounds specifying the functionalities of the
            Service or describing Service Plans, as applicable, provided or made
            available by Prowess to You in the applicable Prowess help
            center(s); provided, however, that Documentation shall specifically
            exclude any “community moderated” forums as provided or accessible
            through such knowledge base(s).
          </p>
          <p>
            End-User: means any person or entity other than Subscriber or User
            with whom Subscriber or its Users interact using the Service.
          </p>
          <p>
            GDPR: means Regulation (EU) 2016/679 of the European Parliament and
            of the Council (General Data Protection Regulation).
          </p>
          <p>
            Order Form: means any Prowess generated service order form executed
            or approved by You with respect to Your subscription to the Service,
            which form may detail, among other things, the number of Users
            authorized to use the Service under Your subscription to the Service
            and the Service Plan applicable to Your subscription to the Service.
          </p>
          <p>
            Other Services: means third party products, applications, services,
            software, products, networks, systems, directories, websites,
            databases and information which the Service links to, or which You
            may connect to or enable in conjunction with the Service, including,
            without limitation, Other Services which may be integrated directly
            into Your Prowess Service.
          </p>
          <p>
            Personal Data: means any information relating to an identified or
            identifiable natural person; an identifiable person is one who can
            be identified, directly or indirectly, in particular by reference to
            an identification number or to one or more factors specific to his
            physical, physiological, mental, economic, cultural or social
            identity.
          </p>
          <p>
            Processing/To Process: means any operation or set of operations
            which is performed upon Personal Data, whether or not by automatic
            means, such as collection, recording, organization, storage,
            adaptation or alteration, retrieval, consultation, use, disclosure
            by transmission, dissemination or otherwise making available,
            alignment or combination, blocking, erasure or destruction.
          </p>
          <p>
            Service: means the tools and services provided by Prowess,
            including, individually and collectively, Software, the API and any
            Documentation. Any new or modified features added to or augmenting
            or otherwise modifying the Service or other updates, modifications
            or enhancements to the Service (“Updates”) are also subject to these
            Terms and Prowess reserves the right to deploy Updates at any time.
          </p>
          <p>
            Service Plan(s): means the packaged service plan(s) and the
            functionality and services associated therewith (as detailed on the
            Website applicable to the Service) for the Services to which You
            subscribe.
          </p>
          <p>
            Website: means www.prowess.app and other websites that Prowess
            operates
          </p>
          <p>
            Software: means software provided by Prowess (either by download or
            access through the internet) that allows Users and End-Users to use
            any functionality in connection with the Service. Subscription Term:
            means the period during which You have agreed to subscribe to the
            Service with respect to any individual User. User: means an
            individual authorized to use a Service through Your Account as an
            administrator, editor, contributor, and/or viewer as identified
            through a unique login. Service Data or Your Data: means all
            electronic data, text, messages or other materials submitted to the
            Service by You, Users and End-Users in connection with Your use of
            the Service, including, without limitation, Personal Data.
          </p>
          <h1>2. GENERAL CONDITIONS; ACCESS TO AND USE OF THE SERVICE</h1>
          <p>
            2.1 During the Subscription Term and subject to compliance by You,
            Users, and End-Users with these Terms, You have the limited right to
            access and use the Service consistent with the Service Plan You
            subscribe to for Your internal business purposes. Without limiting
            the foregoing, Your right to access and use the API is also subject
            to the restrictions and policies implemented by Prowess from time to
            time with respect to the API as set forth in the Documentation or
            otherwise communicated to You in writing.
          </p>
          <p>
            2.2 A high-speed Internet connection is required for proper
            transmission of the Service. You are responsible for procuring and
            maintaining the network connections that connect Your network to the
            Service, including, but not limited to, “browser” software that
            supports protocols used by Prowess, including Secure Socket Layer
            (SSL) protocol or other protocols accepted by Prowess, and to follow
            procedures for accessing services that support such protocols. We
            are not responsible for notifying You, Users or End-Users of any
            upgrades, fixes or enhancements to any such software or for any
            compromise of data, including Your Data, transmitted across computer
            networks or telecommunications facilities (including but not limited
            to the Internet) which are not owned, operated or controlled by
            Prowess. We assume no responsibility for the reliability or
            performance of any connections as described in this section.
          </p>{" "}
          <p>
            2.3 You agree not to (a) license, sublicense, sell, resell, rent,
            lease, transfer, assign, distribute, time share or otherwise
            commercially exploit or make the Service available to any third
            party, other than authorized Users and End-Users in furtherance of
            Your internal business purposes as expressly permitted by these
            Terms; (b) use the Service to Process Personal Data on behalf of any
            third party other than Users or End-Users; (c) modify, adapt, or
            hack the Service or otherwise attempt to gain unauthorized access to
            the Service or related systems or networks; (d) falsely imply any
            sponsorship or association with Prowess, (e) use the Service in any
            unlawful manner, including but not limited to violation of any
            person’s privacy rights; (f) use the Service to send unsolicited or
            unauthorized junk mail, spam, pyramid schemes or other forms of
            duplicative or unsolicited messages; (g) use the Service to store or
            transmit files, materials, data, text, audio, video, images or other
            content that infringes on any person’s intellectual property rights;
            (h) use the Service in any manner that interferes with or disrupts
            the integrity or performance of the Service and its components; (i)
            attempt to decipher, decompile, reverse engineer or otherwise
            discover the source code of any software making up the Service; (j)
            use the Service to knowingly post, transmit, upload, link to, send
            or store any content that is unlawful, racist, hateful, abusive,
            libelous, obscene, or discriminatory; (k) use the Service to store
            or transmit any “protected health information” as that term is
            defined in 45 C.F.R. 160.103; (l) use the Service to knowingly post
            transmit, upload, link to, send or store any viruses, malware,
            Trojan horses, time bombs, or any other similar harmful software
            (“Malicious Software”); or (m) try to use, or use the Service in
            violation of these Terms.
          </p>{" "}
          <p>
            2.4 You are responsible for compliance with the provisions of these
            Terms by Users and End-Users and for any and all activities that
            occur under Your Account, as well as for all Your Data. Without
            limiting the foregoing, You are solely responsible for ensuring that
            use of the Service to store and transmit Your Data is compliant with
            all applicable laws and regulations. You also maintain all
            responsibility for determining whether the Service or the
            information generated thereby is accurate or sufficient for Your
            purposes. Subject to any limitation on the number of individual
            Users available under the Service Plan for which You subscribed,
            access to and use of the Service is restricted to the specified
            number of individual Users permitted under Your subscription to the
            Service. You agree and acknowledge that each User will be identified
            by a unique username and password (“Login”) and that a User Login
            may only be used by one (1) individual. You will not share a User
            Login among multiple individuals. You and Your Users are responsible
            for maintaining the confidentiality of all Login information for
            Your Account.
          </p>{" "}
          <p>
            2.5 In addition to Our rights as set forth in Section 2 and 7.4,
            Prowess reserves the right, in Prowess reasonable discretion, to
            temporarily suspend Your access to and use of the Service: (a)
            during planned downtime for upgrades and maintenance to the Service
            (of which Prowess will use commercially reasonable efforts to notify
            You in advance both through Our forum page and a notice to Your
            Account owner and Users) (“Planned Downtime”); (b) during any
            unavailability caused by circumstances beyond Our reasonable
            control, such as, but not limited to, acts of God, acts of
            government, acts of terror or civil unrest, technical failures
            beyond Our reasonable control (including, without limitation,
            inability to access the Internet), or acts undertaken by third
            parties, including without limitation, distributed denial of service
            attacks; or (c) if We suspect or detect any Malicious Software
            connected to Your Account or use of the Service by You, Users or
            End-Users. We will use commercially reasonable efforts to schedule
            Planned Downtime for weekends (US Pacific time zone) and other
            off-peak hours.
          </p>{" "}
          <p>
            2.6 You acknowledge that Prowess may modify the features and
            functionality of the Service during the Subscription Term.
          </p>
          <p>
            2.7 You may not access the Service if You are a direct competitor of
            Prowess, except with Prowess’s prior written consent. You may not
            access the Services for the purposes of monitoring performance,
            availability, functionality, or for any benchmarking or competitive
            purposes.
          </p>{" "}
          <p>
            2.8 If You register for a free trial for the Service, We will make
            the Service available to You on a trial basis free of charge until
            the earlier of (a) the end of the free trial period for which You
            registered to use the Service; (b) the start date of any
            subscription to the Service purchased by You for such Service; or
            (c) termination of the trial by Us in our sole discretion.
            Additional trial terms and conditions may appear on the trial
            registration web page. Any such additional terms and conditions are
            incorporated into these Terms by reference and are legally binding.
            Please review the applicable Documentation during the trial period
            so that You become familiar with the features and functions of the
            Services under applicable Service Plans before You make Your
            purchase.
          </p>{" "}
          <p>
            2.9 From time to time, We may make Beta Services available to You at
            no charge. You may choose to try such Beta Services in Your sole
            discretion. Beta Services are intended for evaluation purposes and
            not for production use, are not supported, and may be subject to
            additional terms that will be presented to You. Beta Services are
            not considered “Services” under this Agreement; however, all
            restrictions, Our reservation of rights and Your obligations
            concerning the Service, and use of any Third Party Services shall
            apply equally to Your use of Beta Services. Unless otherwise stated,
            any Beta Services trial period will expire upon the earlier of one
            year from the trial start date or the date that a version of the
            Beta Services becomes generally available without the applicable
            Beta Services designation. We may discontinue Beta Services at any
            time in Our sole discretion and may never make them generally
            available. We will have no liability for any harm or damage arising
            out of or in connection with a Beta Service.
          </p>{" "}
          <h1>3. DATA PRIVACY AND SECURITY; CONFIDENTIALITY</h1>
          <p>
            3.1 Subject to the express permissions of these Terms, You and
            Prowess will protect each other’s Confidential Information from
            unauthorized use, access or disclosure in the same manner as each
            protects its own Confidential Information, but with no less than
            reasonable care. Except as otherwise expressly permitted pursuant to
            these Terms, each of us may use each other’s Confidential
            Information solely to exercise our respective rights and perform our
            respective obligations under these Terms and shall disclose such
            Confidential Information solely to those of our respective
            employees, representatives and agents who have a need to know such
            Confidential Information for such purposes and who are bound to
            maintain the confidentiality of, and not misuse, such Confidential
            Information. The provisions of this Section 3 shall supersede any
            non-disclosure agreement by and between You and Prowess entered
            prior to these Terms that would purport to address the
            confidentiality of Your Data and such agreement shall have no
            further force or effect with respect to Your Data.
          </p>{" "}
          <p>
            3.2 Prowess will maintain commercially reasonable administrative,
            physical and technical safeguards to protect the security,
            confidentiality, and integrity of Your Data. These safeguards
            include encryption of Your Data in transmission (using SSL or
            similar technologies), except for certain Other Services that do not
            support encryption, which You may link to through Service at Your
            election. Our compliance with the provisions of this Section 3.2
            shall be deemed compliance with Our obligations to protect Your Data
            as set forth in Section 3.1.
          </p>{" "}
          <p>
            3.3 You agree that Prowess and the service providers We use to
            assist in providing the Service to You shall have the right to
            access Your Account and to use, modify, reproduce, distribute,
            display and disclose Your Data solely to the extent necessary to
            provide the Service, including, without limitation, in response to
            Your support requests. Any third party service providers We utilize
            will only be given access to Your Account and Your Data as is
            reasonably necessary to provide the Service and will be subject to
            (a) confidentiality obligations which are commercially reasonable
            and substantially consistent with the standards described in Section
            3.2; and (b) their agreement to comply with the data transfer
            restrictions applicable to Personal Data as set forth in Section
            3.5. Prowess may also access or disclose information about You, Your
            Account, Users or End-Users, including Your Data, in order to (a)
            comply with the law or respond to lawful requests or legal process;
            (b) protect Prowess’s or its customers’ or partners’ rights or
            property, including enforcement of these Terms or other policies
            associated with the Service; (c) act on a good faith belief that
            such disclosure is necessary to protect personal safety or avoid
            violation of applicable law or regulation.
          </p>{" "}
          <p>
            3.4 We collect certain information about You, Users, and End-Users
            as well as Your and their respective devices, computers and use of
            the Service. We use, disclose, and protect this information as
            described in Our Privacy Policy, the then-current version of which
            is available at www.prowess.app/privacy_policy and is incorporated
            into the Terms. Your Data is currently hosted by Prowess or its
            authorized service partners in data centers located in the United
            States. If Your principal location is within the the United States,
            we will use commercially reasonable efforts to notify You at least
            thirty (30) days before our election to host Personal Data provided
            to Prowess in connection with use of the Service in data centers
            located outside the United States. If You are entitled to this
            notice and do not wish to have Your Personal Data hosted in data
            centers located in such other country or territory, You may
            terminate Your Subscription and Your Account with immediate effect
            upon written notice to Prowess within 30 days or Your receipt of
            such notice.
          </p>{" "}
          <h1>4. INTELLECTUAL PROPERTY RIGHTS</h1>
          <p>
            Each of us shall maintain all rights, title and interest in and to
            all our respective patents, inventions, copyrights, trademarks,
            domain names, trade secrets, know-how and any other intellectual
            property and/or proprietary rights (collectively, “Intellectual
            Property Rights”). The rights granted to You, Users and End-Users to
            use the Service under these Terms do not convey any additional
            rights in the Service, or in any Intellectual Property Rights
            associated therewith. Subject only to limited rights to access and
            use the Service as expressly herein, all rights, title and interest
            in and to the Service and all hardware, software and other
            components of or used to provide the Service, including all related
            intellectual property rights, will remain with and belong
            exclusively to Prowess. Prowess shall have a royalty-free,
            worldwide, transferable, sub-licensable, irrevocable and perpetual
            license to incorporate into the Service or otherwise use any
            suggestions, enhancement requests, recommendations or other feedback
            We receive from You Users, or End-Users, or other third parties
            acting on Your behalf. Prowess, and Prowess’s other product and
            service names, and logos used or displayed on the Service are
            registered or unregistered trademarks of Prowess (collectively,
            “Marks”), and You may only use such Marks to identify You as a
            Subscriber; provided You do not attempt, now or in the future, to
            claim any rights in the Marks, degrade the distinctiveness of the
            Marks, or use the Marks to disparage or misrepresent Prowess, its
            services or products.
          </p>
          <h1>5. THIRD PARTY SERVICES</h1>
          <p>
            If You decide to enable, access or use Other Services, be advised
            that Your access and use of such Other Services is governed solely
            by the terms and conditions of such Other Services, and We do not
            endorse, are not responsible or liable for, and make no
            representations as to any aspect of such Other Services, including,
            without limitation, their content or the manner in which they handle
            data (including Your Data) or any interaction between You and the
            provider of such Other Services. You irrevocably waive any claim
            against Prowess with respect to such Other Services. Prowess is not
            liable for any damage or loss caused or alleged to be caused by or
            in connection with Your enablement, access or use of any such Other
            Services, or Your reliance on the privacy practices, data security
            processes or other policies of such Other Services. You may be
            required to register for or log into such Other Services on their
            respective websites. By enabling any Other Services, You are
            expressly permitting Prowess to disclose Your Login as well as Your
            Data as necessary to facilitate the use or enablement of such Other
            Service.
          </p>
          <h1>6. BILLING, PLAN MODIFICATIONS AND PAYMENTS</h1>
          <p>
            6.1 Unless otherwise indicated on a Form referencing these Terms and
            subject to Section 6.2, all charges associated with Your access to
            and use of the Service (“Subscription Charges”) are due in full upon
            commencement of Your Subscription Term. If You fail to pay Your
            Subscription Charges or charges for other services indicated on any
            Form referencing these Terms within five (5) business days of Our
            notice to You that payment is due or delinquent, or if You do not
            update payment information upon Our request, in addition to Our
            other remedies, We may suspend or terminate access to and use of the
            Service by You, Users and End-Users.
          </p>
          <p>
            6.2 If You choose to upgrade Your Service Plan or increase the
            number of authorized Users during Your Subscription Term (a
            “Subscription Upgrade”), any incremental Subscription Charges
            associated with such Subscription Upgrade will be prorated over the
            remaining period of Your then-current Subscription Term, charged to
            Your Account and due and payable upon implementation of such
            Subscription Upgrade. In any future Subscription Term, Your
            Subscription Charges will reflect any such Subscription Upgrades.
          </p>
          <p>
            6.3 No refunds or credits for Subscription Charges or other fees or
            payments will be provided to You if You elect to downgrade Your
            Service Plan. Downgrading Your Service Plan may cause loss of
            content, features, or capacity of the Service as available to You
            under Your Account, and Prowess does not accept any liability for
            such loss. Prowess reserves the right to contact You about special
            pricing if You maintain an exceptionally high number of Users,
            End-Users or other excessive stress on the Service.
          </p>
          <p>
            6.4 Unless otherwise stated, Our charges do not include any taxes,
            levies, duties or similar governmental assessments, including
            value-added, sales, use or withholding taxes assessable by any
            local, state, provincial or foreign jurisdiction (collectively
            “Taxes”). You are responsible for paying Taxes except those
            assessable against Prowess based on its income. We will invoice You
            for such Taxes if We believe We have a legal obligation to do so and
            You agree to pay such Taxes if so invoiced.
          </p>
          <p>
            6.5 If You pay by credit card, the Service provides an interface for
            the account owner to change credit card information (e.g. upon card
            renewal). The Account owner will receive a receipt upon each receipt
            of payment by Prowess, or they may obtain a receipt from within the
            Service to track subscription status. You hereby authorize Prowess
            to bill Your credit card or another payment instrument in advance on
            a periodic basis in accordance with the terms of the Service Plan
            until you terminate your Subscription, and you further agree to pay
            any Subscription Charges so incurred. Prowess uses a third-party
            intermediary to manage credit card processing and this intermediary
            is not permitted to store, retain or use Your billing information
            except to process Your credit card information for Prowess.
          </p>
          <h1>7. CANCELLATION AND TERMINATION</h1>
          <p>
            7.1 Either You or Prowess may elect to terminate Your Account and
            subscription to the Service as of the end of your then-current
            Subscription Term by providing notice, in accordance with these
            Terms, on or prior to the date thirty (30) days preceding the end of
            such Subscription Term. Unless Your Account and subscription to the
            Service is so terminated, Your subscription to the Service will
            renew for a Subscription Term equivalent in length to the then
            expiring Subscription Term. Unless otherwise provided for in any
            Form, the Subscription Charges applicable to Your subscription to
            the Service for any such subsequent Subscription Term shall be Our
            standard Subscription Charges for the Service Plan to which You have
            subscribed as of the time such subsequent Subscription Term
            commences.
          </p>
          <p>
            7.2 No refunds or credits for Subscription Charges or other fees or
            payments will be provided to You if You elect to terminate Your
            subscription to the Service or cancel Your Account prior to the end
            of Your then effective Subscription Term. Following the termination
            or cancellation of Your subscription to the Service and/or Account,
            We reserve the right to delete all Your Data in the normal course of
            operation. Your Data cannot be recovered once Your Account is
            canceled.
          </p>
          <p>
            7.3 If You terminate Your subscription to the Service or cancel Your
            Account prior to the end of Your then effective Subscription Term or
            We effect such termination or cancellation pursuant to Section
            2.5(c) or 7.4, in addition to other amounts You may owe Prowess, You
            must immediately pay any then unpaid Subscription Charges associated
            with the remainder of such Subscription Term. This amount will not
            be payable by You in the event You terminate Your subscription to
            the Service or cancel Your Account as a result of a material breach
            of these Terms by Prowess, provided that You provide advance notice
            of such breach to Prowess and afford Prowess not less than thirty
            (30) days to reasonably cure such breach.
          </p>
          <p>
            7.4 Prowess reserves the right to modify, suspend or terminate the
            Service (or any part thereof), Your Account or Your and/or Users’
            and/or End-Users’ rights to access and use the Service, and remove,
            disable and discard any of Your Data if We believe that You, Users
            or End-Users have violated these Terms. This includes the removal or
            disablement of Your Data in accordance with Our Copyright
            Infringement Notice and Takedown Policy available at
            www.Prowess.app/ippolicy. Unless legally prohibited from doing so,
            Prowess will use commercially reasonable efforts to contact You
            directly via email to notify You when taking any of the foregoing
            actions. Prowess shall not be liable to You, Users, End-Users or any
            other third party for any such modification, suspension or
            discontinuation of Your rights to access and use the Service. Any
            suspected fraudulent, abusive, or illegal activity by You, Users or
            End-Users may be referred to law enforcement authorities at Our sole
            discretion.
          </p>
          <h1>8. PROMOTIONAL CREDITS POLICY</h1>
          <p>
            We may, at Our sole discretion, choose to offer credits for the
            Service in various ways, including but not limited to, coupons,
            promotional campaigns, and referrals for Prowess services such as
            training. Prowess reserves the right to award credits at its sole
            discretion. Credits have no monetary or cash value and can only be
            used by You to offset Your subsequent payments of Subscription
            Charges for the Service. Credits may only be applied to Subscription
            Charges due for the Service specifically identified by Prowess when
            issuing the credit. Credits can only be used by You and are
            non-transferable. To the extent that You have been awarded credits,
            unless the instrument (including any coupon) states an earlier
            expiration date, credits shall expire and no longer be redeemable
            twelve (12) months from the date the credit was issued.
          </p>
          <h1>9. DISCLAIMER OF WARRANTIES</h1>
          <p>
            THE WEBSITE AND THE SERVICE, INCLUDING ALL SERVER AND NETWORK
            COMPONENTS ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS,
            WITHOUT ANY WARRANTIES OF ANY KIND TO THE FULLEST EXTENT PERMITTED
            BY LAW, AND Prowess EXPRESSLY DISCLAIMS ANY AND ALL WARRANTIES,
            WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, ANY
            IMPLIED WARRANTIES OF MERCHANTABILITY, TITLE, FITNESS FOR A
            PARTICULAR PURPOSE, AND NON-INFRINGEMENT. YOU ACKNOWLEDGE THAT
            Prowess DOES NOT WARRANT THAT THE SERVICE WILL BE UNINTERRUPTED,
            TIMELY, SECURE, ERROR-FREE OR FREE FROM VIRUSES OR OTHER MALICIOUS
            SOFTWARE, AND NO INFORMATION OR ADVICE OBTAINED BY YOU FROM Prowess
            OR THROUGH THE SERVICE SHALL CREATE ANY WARRANTY NOT EXPRESSLY
            STATED IN THESE TERMS.
          </p>
          <h1>10. LIMITATION OF LIABILITY</h1>
          <p>
            10.1 UNDER NO CIRCUMSTANCES AND UNDER NO LEGAL THEORY (WHETHER IN
            CONTRACT, TORT, NEGLIGENCE OR OTHERWISE) WILL EITHER PARTY TO THESE
            TERMS, OR THEIR AFFILIATES, OFFICERS, DIRECTORS, EMPLOYEES, AGENTS,
            SERVICE PROVIDERS, SUPPLIERS OR LICENSORS BE LIABLE TO THE OTHER
            PARTY OR ANY THIRD PARTY FOR ANY INDIRECT, INCIDENTAL, SPECIAL,
            EXEMPLARY, CONSEQUENTIAL, PUNITIVE OR OTHER SIMILAR DAMAGES,
            INCLUDING LOST PROFITS, LOST SALES OR BUSINESS, LOST DATA, BUSINESS
            INTERRUPTION OR ANY OTHER LOSS INCURRED BY SUCH PARTY OR THIRD PARTY
            IN CONNECTION WITH THESE TERMS OR THE SERVICE, REGARDLESS OF WHETHER
            SUCH PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF OR COULD HAVE
            FORESEEN SUCH DAMAGES.
          </p>
          <p>
            10.2 NOTWITHSTANDING ANYTHING TO THE CONTRARY IN THESE TERMS,
            Prowess’S AGGREGATE LIABILITY TO YOU OR ANY THIRD PARTY ARISING OUT
            OF THESE TERMS OR OTHERWISE IN CONNECTION WITH ANY SUBSCRIPTION TO,
            OR USE OR EMPLOYMENT OF THE SERVICE, SHALL IN NO EVENT EXCEED THE
            SUBSCRIPTION CHARGES PAID BY YOU DURING THE TWELVE (12) MONTHS PRIOR
            TO THE FIRST EVENT OR OCCURRENCE GIVING RISE TO SUCH LIABILITY. YOU
            ACKNOWLEDGE AND AGREE THAT THE ESSENTIAL PURPOSE OF THIS SECTION
            10.2 IS TO ALLOCATE THE RISKS UNDER THESE TERMS BETWEEN THE PARTIES
            AND LIMIT POTENTIAL LIABILITY GIVEN THE SUBSCRIPTION CHARGES, WHICH
            WOULD HAVE BEEN SUBSTANTIALLY HIGHER IF Prowess WERE TO ASSUME ANY
            FURTHER LIABILITY OTHER THAN AS SET FORTH HEREIN. Prowess HAS RELIED
            ON THESE LIMITATIONS IN DETERMINING WHETHER TO PROVIDE YOU THE
            RIGHTS TO ACCESS AND USE THE SERVICE PROVIDED FOR IN THESE TERMS.
          </p>
          <p>
            10.3 Some jurisdictions do not allow the exclusion of implied
            warranties or limitation of liability for incidental or
            consequential damages, which means that some of the above
            limitations may not apply to You. IN THESE JURISDICTIONS, Prowess’S
            LIABILITY WILL BE LIMITED TO THE GREATEST EXTENT PERMITTED BY LAW.
          </p>
          <h1>11. INDEMNIFICATION</h1>
          <p>
            11.1 Prowess will indemnify and hold You harmless, from and against
            any claim against You by reason of Your use of the Service as
            permitted hereunder, brought by a third party alleging that the
            Service infringes or misappropriates a third party’s valid patent,
            copyright, trademark or trade secret (an “IP Claim”). Prowess shall,
            at its expense, defend such IP Claim and pay damages finally awarded
            against You in connection therewith, including the reasonable fees
            and expenses of the attorneys engaged by Prowess for such defense,
            provided that (a) You promptly notify Prowess of the threat or
            notice of such IP Claim, (b) Prowess will have the sole and
            exclusive control and authority to select defense attorneys, defend
            and/or settle any such IP Claim, and (c) You fully cooperate with
            Prowess in connection therewith. If use of the Service by You, Users
            or End-Users has become, or in Prowess’s opinion is likely to
            become, the subject of any such IP Claim, Prowess may at its option
            and expense (a) procure for You the right to continue using the
            Service as set forth hereunder; (b) replace or modify the Service to
            make it non-infringing; or (c) if options (a) or (b) are not
            commercially and reasonably practicable as determined by Prowess,
            terminate Your subscription to the Service and repay You, on a
            pro-rated basis, any Subscription Charges previously paid to Prowess
            for the corresponding unused portion of Your Subscription Term.
            Prowess will have no liability or obligation under this Section 11.1
            with respect to any IP Claim if such claim is caused in whole or in
            part by (i) compliance with designs, data, instructions or
            specifications provided by You; (ii) modification of the Service by
            anyone other than Prowess; or (iii) the combination, operation or
            use of the Service with other hardware or software where the Service
            would not by itself be infringing. The provisions of this Section
            11.1 state the sole, exclusive and entire liability of Prowess to
            You and constitute Your sole remedy with respect to an IP Claim
            brought by reason of access to or use of the Service by You, Users
            or End-Users.
          </p>
          <p>
            11.2 You will indemnify and hold Prowess harmless against any claim
            brought by a third party against Prowess arising from or related to
            use of the Service by You, Users or End-Users in breach of these
            Terms or matters which You have expressly agreed to be responsible
            pursuant to these Terms; provided (a) We shall promptly notify You
            of the threat or notice of such claim; (b) You will have the sole
            and exclusive control and authority to select defense attorneys, and
            defend and/or settle any such claim (however, You shall not settle
            or compromise any claim that results in liability or admission of
            any liability by Us without Our prior written consent); and (c) We
            fully cooperate with You in connection therewith.
          </p>
          <h1>12. ASSIGNMENT; ENTIRE AGREEMENT; REVISIONS</h1>
          <p>
            12.1 You may not, directly or indirectly, by operation of law or
            otherwise, assign all or any part of these Terms or Your rights
            under these Terms or delegate performance of Your duties under these
            Terms without Prowess’s prior consent, which consent will not be
            unreasonably withheld. We may, without Your consent, assign Our
            agreement with You in connection with any merger or change of
            control of Prowess or the sale of all or substantially all of Our
            assets provided that any such successor agrees to fulfill its
            obligations pursuant to these Terms. Subject to the foregoing
            restrictions, these Terms will be fully binding upon, inure to the
            benefit of and be enforceable by the parties and their respective
            successors and assigns.
          </p>
          <p>
            12.2 These Terms, together with any Form(s), constitute the entire
            agreement and supersede any and all prior agreements between You and
            Prowess with regard to the subject matter hereof. These Terms and
            any Form(s) shall apply in lieu of the terms or conditions in any
            purchase order or other order documentation You or any Entity which
            you represent provides (all such terms or conditions being null and
            void), and, except as expressly stated herein, there are no other
            agreements, representations, warranties, or commitments which may be
            relied upon by either party with respect to the subject matter
            hereof. Notwithstanding the foregoing, additional terms may apply to
            certain features or functionality Prowess offers through the Service
            (the “Additional Terms”). In those instances, We will notify You of
            such Additional Terms prior to the activation of these features or
            functionality and the activation of these features or functionality
            in Your Account will be considered acceptance of the Additional
            Terms. All such Additional Terms will be considered incorporated
            into these Terms when You or any User designated as an administrator
            on your Account activates the feature or functionality. Where there’
            is a conflict between these Terms and the Additional Terms, the
            Additional Terms will control.
          </p>
          <p>
            12.3 We may amend these Terms from time to time, in which case the
            new Terms will supersede prior versions. We will notify You not less
            than ten (10) days prior to the effective date of any such amendment
            and Your continued use of the Service following the effective date
            of any such amendment may be relied upon by Prowess as Your consent
            to any such amendment. Prowess’s failure to enforce at any time any
            provision of these Terms does not constitute a waiver of that
            provision or of any other provision of the Terms.
          </p>
          <h1>13. SEVERABILITY</h1>
          <p>
            If any provision in these Terms is held by a court of competent
            jurisdiction to be unenforceable, such provision shall be modified
            by the court and interpreted so as to best accomplish the original
            provision to the fullest extent permitted by law, and the remaining
            provisions of these Terms shall remain in effect.
          </p>
          <h1>14. EXPORT COMPLIANCE AND USE RESTRICTIONS</h1>
          <p>
            The Service and other Software or components of the Service which
            Prowess may provide or make available to You, Users or End-Users may
            be subject to U.S. export control and economic sanctions laws. You
            agree to comply with all such laws and regulations as they relate to
            access to and use of the Service, Software and such other components
            by You, Users and End-Users. You shall not access or use the Service
            if You are located in any jurisdiction in which the provision of the
            Service, Software or other components is prohibited under U.S. or
            other applicable laws or regulations (a “Prohibited Jurisdiction”)
            and You shall not provide access to the Service to any government,
            entity or individual located in any Prohibited Jurisdiction. You
            represent, warrant and covenant that (i) You are not named on any
            U.S. government list of persons or entities prohibited from
            receiving U.S. exports, or transacting with any U.S. person, (ii)
            You are not a national of, or a company registered in, any
            Prohibited Jurisdiction, (iii) You shall not permit Users or
            End-Users to access or use the Service in violation of any U.S. or
            other applicable export embargoes, prohibitions or restrictions, and
            (iv) You shall comply with all applicable laws regarding the
            transmission of technical data exported from the United States and
            the country in which You, Users and End-Users are located.
          </p>
          <h1>15. RELATIONSHIP OF THE PARTIES</h1>
          <p>
            The parties are independent contractors. These Terms do not create a
            partnership, franchise, joint venture, agency, fiduciary or
            employment relationship among the parties.
          </p>
          <h1>16. SURVIVAL</h1>
          <p>
            Sections 1, 3, 4 and 9-19 shall survive any termination of our
            agreement with respect to use of the Service by You, Users or
            End-Users. Termination of such agreement shall not limit Your or
            Prowess’s liability for obligations accrued as of or prior to such
            termination or for any breach of these Terms.
          </p>
          <h1>17. NOTICE</h1>
          <p>
            All notices to be provided by Prowess to You under these Terms may
            be delivered in writing (i) by nationally recognized overnight
            delivery service (“Courier”) or US mail to the contact mailing
            address provided by You on any Form; or (ii) electronic mail to the
            electronic mail address provided for Your Account owner. You must
            give notice to us in writing by Courier or US Mail to the following
            address: Prowess, Inc., Attn: Legal Department, 544 Guerrero #3, San
            Francisco, CA 94110 USA. All notices shall be deemed to have been
            given immediately upon delivery by electronic mail, or if otherwise
            delivered upon receipt or, if earlier, two (2) business days after
            being deposited in the mail or with a Courier as permitted above.
          </p>
          <h1>18. GOVERNING LAW</h1>
          <p>
            These Terms shall be governed by the laws of the State of California
            without regard to conflict of laws principles. You hereby expressly
            agree to submit to the exclusive personal jurisdiction of the
            federal and state courts of the State of California, San Francisco
            County, for the purpose of resolving any dispute relating to the
            Terms or access to or use of the Service by You, Users or End-Users.
          </p>
          <h1>19. FEDERAL GOVERNMENT END USE PROVISIONS</h1>
          <p>
            If You are a U.S. federal government department or agency or
            contracting on behalf of such department or agency, this Service is
            a “Commercial Item” as that term is defined at 48 C.F.R. §2.101,
            consisting of “Commercial Computer Software” and “Commercial
            Computer Software Documentation”, as those terms are used in 48
            C.F.R. §12.212 or 48 C.F.R. §227.7202. Consistent with 48 C.F.R.
            §12.212 or 48 C.F.R. §227.7202-1 through 227.7202-4, as applicable,
            this Service is licensed to You with only those rights as provided
            under the terms and conditions of these Terms.
          </p>
          <h1>Exhibit A</h1>
          <h2>Data Processing Agreement</h2>
          <p>
            This Data Processing Agreement (the “DPA”) is made between Prowess
            as the data processor (the “Data Processor”) and the Subscriber as
            the data controller (the “Data Controller”) to reflect the parties’
            agreement with respect to the terms governing the Processing of
            Personal Data under the Terms. In case of discrepancy between DPA
            and the Terms, DPA prevails.
          </p>
          <h2>1. DEFINITIONS</h2>
          <p>
            1.1 Capitalized terms used in this DPA shall have the meanings given
            to them in the Terms and below:
          </p>
          <p>
            (a) Applicable Data Protection Law: means the following data
            protection law(s)): means (i) where Data Controller is established
            in a European Economic Area (“EEA”) member state or where Data
            Controller’s Agents or End-Users access the Services from an EEA
            member state: GDPR; and (ii) where Data Controller is established in
            Switzerland, the Swiss Federal Act of 19 June 1992 on Data
            Protection (as may be amended or superseded).
          </p>
          <p>
            (d) Sub-processor: means any third party data processor engaged by
            Data Processor, who receives Personal Data from Data Processor for
            processing on behalf of Data Controller and in accordance with Data
            Controller’s instructions (as communicated by Data Processor) and
            the terms of its written subcontract.
          </p>
          <p>
            (e) Supervisor: means any Data Protection Supervisory Authority with
            competence over Data Controller’s and Data Processor’s Processing of
            Personal Data.
          </p>
          <h2>2. PURPOSE</h2>
          <p>
            2.1 Pursuant to the Terms the Data Controller is granted a license
            to access and use the Service. In providing the Service, Data
            Processor will engage, on behalf of Data Controller, in the
            Processing of Personal Data submitted to and stored within the
            Service by Data Controller.
          </p>
          <p>
            2.2 The Parties are entering into this DPA to ensure that the
            Processing by Data Processor of Personal Data, within the Service by
            Data Controller and/or on its behalf, is done in a manner compliant
            with Applicable Data Protection Law and its requirements regarding
            the collection, use and retention of Personal Data of Data Subjects.
          </p>
          <h2>3. OWNERSHIP OF THE SERVICE DATA</h2>
          <p>
            3.1 As between the Parties, all Service Data Processed under the
            terms of this DPA and the Terms shall remain the property of Data
            Controller. Under no circumstances will Data Processor act, or be
            deemed to act, as a “controller” (or equivalent concept) of the
            Service Data Processed within the Service under any Applicable Data
            Protection Law.
          </p>
          <h2>4. OBLIGATIONS OF DATA PROCESSOR</h2>
          <p>
            4.1 The Parties agree that the subject-matter and duration of
            Processing performed by Data Processor under this DPA, including the
            nature and purpose of Processing, the type of Personal Data, and
            categories of Data Subjects, shall be as described in Appendix 1 of
            this DPA and in the Terms.
          </p>
          <p>
            4.2 As part of Data Processor providing the Service to Data
            Controller under the Terms, Data Processor agrees and declares as
            follows:
          </p>
          <p>
            (a) to process Personal Data in accordance with Data Controller’s
            documented instructions as set out in the Terms and this DPA or as
            otherwise necessary to provide the Service, except where required
            otherwise by applicable laws (and provided such laws do not conflict
            with Applicable Data Protection Law); in such case, Data Processor
            shall inform Data Controller of that legal requirement upon becoming
            aware of the same (except where prohibited by applicable laws);
          </p>
          <p>
            (b) to ensure that all staff and management of any member of the
            Processor are fully aware of their responsibilities to protect
            Personal Data in accordance with this DPA and have committed
            themselves to confidentiality or are under an appropriate statutory
            obligation of confidentiality;
          </p>
          <p>
            (c) to implement and maintain appropriate technical and
            organizational measures to protect Personal Data against accidental
            or unlawful destruction or accidental loss, alteration, unauthorized
            disclosure or access (a “Data Security Breach”), provided that such
            measures shall take into account the state of the art, the costs of
            implementation and the nature, scope, context and purposes of
            Processing, as well as the risk of varying likelihood and severity
            for the rights and freedoms of natural persons, so as to ensure a
            level of security appropriate to the risks represented by the
            Processing and the nature of the Data to be protected;
          </p>
          <p>
            (d) to notify Data Controller, without undue delay, in the event of
            a confirmed Data Security Breach affecting Data Controller’s Service
            Data and to cooperate with Data Controller as necessary to mitigate
            or remediate the Data Security Breach;
          </p>
          <p>
            (e) to comply with the requirements of Section 5 (Use of
            Sub-processors) when engaging a Sub-processor;
          </p>
          <p>
            (f) taking into account the nature of the Processing, to assist Data
            Controller (including by appropriate technical and organizational
            measures), insofar as it is commercially reasonable, to fulfil Data
            Controller’s obligation to respond to requests from Data Subjects to
            exercise their rights under Applicable Data Protection Law (a “Data
            Subject Request”). In the event Data Processor receives a Data
            Subject Request directly from a Data Subject, it shall (unless
            prohibited by law) direct the Data Subject to the Data Controller in
            the first instance. However, in the event Data Controller is unable
            to address the Data Subject Request, taking into account the nature
            of the Processing and the information available to Data Processor,
            Data Processor, shall, on Data Controller’s request and at Data
            Controller’s reasonable expense, address the Data Subject Request,
            as required under the Applicable Data Protection Law;
          </p>
          <p>
            (g) upon request, to provide Data Controller with commercially
            reasonable information and assistance, taking into account the
            nature of the Processing and the information available to Data
            Processor, to help Data Controller to conduct any data protection
            impact assessment or Supervisor consultation it is required to
            conduct under Applicable Data Protection Law;
          </p>
          <p>
            (h) upon termination of Data Controller’s access to and use of the
            Service, to comply with the requirements of Section 9 (Return and
            Destruction of Personal Data);
          </p>
          <p>
            (i) to comply with the requirements of Section 6 (Audit) in order to
            make available to Data Controller information that demonstrates Data
            Processor’s compliance with this DPA; and
          </p>
          <p>
            (j) to appoint a security officer who will act as a point of contact
            for Data Controller, and coordinate and control compliance with this
            DPA, including the measures detailed in Exhibit A to this DPA.
          </p>
          <p>
            (k) Data Processor shall immediately inform Data Controller if, in
            its opinion, Data Controller’s Processing instructions infringe any
            law or regulation. In such event, Data Processor is entitled to
            refuse Processing of Personal Data that it believes to be in
            violation of any law or regulation.
          </p>
          <h2>5. USE OF SUB-PROCESSORS</h2>
          <p>
            5.1 Data Controller agrees that Data Processor may appoint
            Sub-processors to assist it in providing the Service and Processing
            Personal Data provided that such Sub-processors:
          </p>
          <p>
            (a) agree to act only on Data Processor’s instructions when
            Processing the Personal Data (which instructions shall be consistent
            with Data Controller’s Processing instructions to Data Processor);
            and
          </p>
          <p>
            (b) agree to protect the Personal Data to a standard consistent with
            the requirements of this DPA, including by implementing and
            maintaining appropriate technical and organizational measures to
            protect the Personal Data they Process consistent with the Security
            Standards described in Appendix 2.
          </p>
          <p>
            5.2 Data Processor agrees and warrants to remain liable to Data
            Controller for the subcontracted Processing services of any of its
            direct or indirect Sub-Processors under this DPA. Data Processor
            shall maintain an up-to-date list of the names and location of all
            Sub-Processors used for the Processing of Personal Data under this
            DPA{" "}
            <a href="https://docs.google.com/document/d/1xes0zZI85LsUJewYmRoznjt7I5-lOB7QIHrsRF06Q7k/">
              in this live document
            </a>
            . Data Processor shall update the list of any Sub-Processor to be
            appointed at least 30 days prior to the date on which the
            Sub-Processor shall commence processing Personal Data.
          </p>
          <p>
            5.3 In the event that Data Controller objects to the Processing of
            its Personal Data by any newly appointed Sub-Processor as described
            in Section 5.2, it shall inform Data Processor immediately. In such
            event, Data Processor will either (a) instruct the Sub-Processor to
            cease any further processing of Data Controller’s Personal Data, in
            which event this DPA shall continue unaffected, or (b) allow Data
            Controller to terminate this DPA (and any related services DPA with
            Data Processor) immediately and provide it with a pro rata
            reimbursement of any sums paid in advance for Services to be
            provided but not yet received by Data Controller as of the effective
            date of termination.
          </p>
          <p>
            5.4 In addition, and as stated in the Terms, the Service provides
            links to integrations with Third Party Services, including, without
            limitation, certain Third Party Services which may be integrated
            directly into Data Controller’s account or instance in the Service.
            If Data Controller elects to enable, access or use such Third Party
            Services, its access and use of such Third Party Services is
            governed solely by the terms and conditions and privacy policies of
            such Third Party Services, and Data Processor does not endorse, is
            not responsible or liable for, and makes no representations as to
            any aspect of such Third Party Services, including, without
            limitation, their content or the manner in which they handle Service
            Data (including Personal Data) or any interaction between Data
            Controller and the provider of such Third Party Services. Data
            Processor is not liable for any damage or loss caused or alleged to
            be caused by or in connection with Data Controller’s enablement,
            access or use of any such Third Party Services, or Data Controller’s
            reliance on the privacy practices, data security processes or other
            policies of such Third Party Services. The providers of Third Party
            Services shall not be deemed Sub-processors for any purpose under
            this DPA.
          </p>
          <h2>6. AUDIT</h2>
          <p>
            6.1 The Parties acknowledge that Data Processor may use external
            auditors to verify the adequacy of its security measures, including
            the security of the physical data centres from which Data Processor
            provides its data processing services.
          </p>
          <p>
            6.2 Data Processor shall provide responsive and detailed information
            to Data Controller’s requests for information (including any
            requests by Data Controller under instruction from Data Subjects),
            which may include responses to relevant information security and
            audit questionnaires.
          </p>{" "}
          <p>
            6.3 At Data Controller’s written request, Data Processor will
            provide Data Controller with a confidential summary of the Report
            (“Summary Report”) so that Data Controller can reasonably verify
            Data Processor’s compliance with the security and audit obligations
            under this DPA. The Summary Report will constitute Data Processor’s
            Confidential Information under the confidentiality provisions of
            Data Processor’s Terms.
          </p>{" "}
          <h2>7. INTERNATIONAL DATA EXPORTS</h2>
          <p>
            7.1 Data Controller acknowledges that Data Processor and its
            Sub-processors may maintain data processing operations in countries
            that are outside of the EEA and Switzerland. As such, both Data
            Processor and its Sub-processors may Process Personal Data in
            non-EEA and non-Swiss countries. This will apply even where Data
            Controller has agreed with Data Processor to host Personal Data in
            the EEA if such non-EEA Processing is necessary to provide
            support-related or other services requested by Data Controller.
          </p>
          <h2>8. OBLIGATIONS OF DATA CONTROLLER</h2>
          <p>
            8.1 As part of Data Controller receiving the Service under the
            Terms, Data Controller agrees and declares as follows:
          </p>
          <p>
            (a) it is solely responsible for the accuracy of Personal Data and
            the means by which such Personal Data is acquired and the Processing
            of Personal Data by Data Controller, including instructing
            Processing by Data Processor in accordance with this DPA, is and
            shall continue to be in accordance with all the relevant provisions
            of the Applicable Data Protection Law, particularly with respect to
            the collection, security, protection and disclosure of Personal
            Data;
          </p>
          <p>
            (b) that if Processing by Data Processor involves any “special” or
            “sensitive” categories” of Personal Data (as defined under
            Applicable Data Protection Law), Data Controller has collected such
            Personal Data in accordance with Applicable Data Protection Law;
          </p>
          <p>(c) that Data Controller will inform its Data Subjects:</p>
          <p>
            (i) about its use of data processors to Process their Personal Data,
            including Data Processor, to the extent required under Applicable
            Data Protection Law; and
          </p>
          <p>
            (ii) that their Personal Data may be Processed outside of the
            European Economic Area;
          </p>
          <p>
            (d) That it shall notify to the Data Processor the contact details
            of EU representative of the Data Controller, if applicable; and of
            the data protection officer of the Data Controller, if appointed;
          </p>{" "}
          <p>
            (e) that it shall respond in reasonable time and to the extent
            reasonably practicable to enquiries by Data Subjects regarding the
            Processing of their Personal Data by Data Controller, and to give
            appropriate instructions to Data Processor in a timely manner; and
          </p>{" "}
          <p>
            (f) that it shall respond in a reasonable time to enquiries from a
            Supervisor regarding the Processing of relevant Personal Data by
            Data Controller.
          </p>{" "}
          <h2>9. RETURN AND DESTRUCTION OF PERSONAL DATA</h2>
          <p>
            9.1 Upon the termination of Data Controller’s access to and use of
            the Service, Data Processor will up to thirty (30) days following
            such termination permit Data Controller to export its Service Data,
            at its expense, in accordance with the capabilities of the Service.
            Following such period, Data Processor shall have the right to delete
            all Service Data stored or Processed by Data Processor on behalf of
            Data Controller in accordance with Data Processor’s deletion
            policies and procedures. Data Controller expressly consents to such
            deletion.
          </p>{" "}
          <h2>10. DURATION</h2>
          <p>
            10.1 This DPA will remain in force as long as Data Processor
            Processes Personal Data on behalf of Data Controller under the
            Terms.
          </p>
          <h2>11. LIMITATION ON LIABILITY</h2>
          <p>
            11.1 UNDER NO CIRCUMSTANCES AND UNDER NO LEGAL THEORY (WHETHER IN
            CONTRACT, TORT, NEGLIGENCE OR OTHERWISE) WILL EITHER PARTY TO THIS
            DPA, OR THEIR AFFILIATES, OFFICERS, DIRECTORS, EMPLOYEES, AGENTS,
            SERVICE PROVIDERS, SUPPLIERS OR LICENSORS BE LIABLE TO THE OTHER
            PARTY OR ANY THIRD PARTY FOR ANY LOST PROFITS, LOST SALES OR
            BUSINESS, LOST DATA (BEING DATA LOST IN THE COURSE OF TRANSMISSION
            VIA DATA CONTROLLER’S SYSTEMS OR OVER THE INTERNET THROUGH NO FAULT
            OF DATA PROCESSOR), BUSINESS INTERRUPTION, LOSS OF GOODWILL, OR FOR
            ANY TYPE OF INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, CONSEQUENTIAL
            OR PUNITIVE LOSS OR DAMAGES, OR ANY OTHER LOSS OR DAMAGES INCURRED
            BY THE OTHER PARTY OR ANY THIRD PARTY IN CONNECTION WITH THIS DPA,
            OR THE SERVICES, REGARDLESS OF WHETHER SUCH PARTY HAS BEEN ADVISED
            OF THE POSSIBILITY OF OR COULD HAVE FORESEEN SUCH DAMAGES.
          </p>
          <p>
            11.2 NOTWITHSTANDING ANYTHING TO THE CONTRARY IN THIS DPA OR THE
            TERMS, DATA PROCESSOR’S AGGREGATE LIABILITY TO DATA CONTROLLER OR
            ANY THIRD PARTY ARISING OUT OF THIS DPA AND ANY LICENSE, USE OR
            EMPLOYMENT OF THE SERVICE, SHALL IN NO EVENT EXCEED THE LIMITATIONS
            SET FORTH IN THE TERMS.
          </p>{" "}
          <p>
            11.3 FOR THE AVOIDANCE OF DOUBT, THIS SECTION SHALL NOT BE CONSTRUED
            AS LIMITING THE LIABILITY OF EITHER PARTY WITH RESPECT TO CLAIMS
            BROUGHT BY DATA-SUBJECTS.
          </p>{" "}
          <h1>12. MISCELLANEOUS</h1>
          <p>
            12.1 This DPA may not be amended or modified except by a writing
            signed by both Parties hereto. This DPA may be executed in
            counterparts. The terms and conditions of this DPA are confidential
            and each party agrees and represents, on behalf of itself, its
            employees and agents to whom it is permitted to disclose such
            information that it will not disclose such information to any third
            party; provided, however, that each party shall have the right to
            disclose such information to its officers, directors, employees,
            auditors, attorneys and third party contractors who are under an
            obligation to maintain the confidentiality thereof and further may
            disclose such information as necessary to comply with an order or
            subpoena of any administrative agency or court of competent
            jurisdiction or as reasonably necessary to comply with any
            applicable law or regulation. Data Controller may not, directly or
            indirectly, by operation of law or otherwise, assign all or any part
            of its rights under this DPA or delegate performance of its duties
            under this DPA without Data Processor’s prior consent, which consent
            will not be unreasonably withheld. Data Processor may, without Data
            Controller’s consent, assign this DPA to any affiliate or in
            connection with any merger or change of control of Data Processor or
            the sale of all or substantially all of its assets provided that any
            such successor agrees to fulfil its obligations pursuant to this
            DPA. Subject to the foregoing restrictions, this DPA will be fully
            binding upon, inure to the benefit of and be enforceable by the
            Parties and their respective successors and assigns. This DPA and
            the Terms constitute the entire understanding between the Parties
            with respect to the subject matter herein, and shall supersede any
            other arrangements, negotiations or discussions between the Parties
            relating to that subject-matter.
          </p>
          <h2>13. GOVERNING LAW AND JURISDICTION</h2>
          <p>
            13.1 This DPA shall be governed by the laws of the State of
            California without regard to conflict of laws principles. You hereby
            expressly agree to submit to the exclusive personal jurisdiction of
            the federal and state courts of the State of California, San
            Francisco County, for the purpose of resolving any dispute relating
            to this DPA
          </p>
          <p>Appendix 1: Subject Matter and Details of the Data Processing</p>
          <p>Subject Matter</p>
          <p>
            Data Processor’s provision of the Services and related technical
            support to the Data Controller.
          </p>
          <p>Duration of the Processing</p>
          <div>
            The applicable Subscription Term (as defined in the Terms) plus the
            period from expiry of such Subscription Term until deletion of all
            Service Data by the Data Processor in accordance with the DPA.
            Nature and Purpose of the Processing The Data Processor will process
            Service Data, which qualify as Personal Data, submitted, stored,
            sent or received by the Data Controller, Users or End-Users (both as
            defined in the Terms) via the Services for the purposes of providing
            the Services and related technical support to Customer in accordance
            with the DPA. Categories of Data Personal data submitted, stored,
            sent or received by the Data Controller, Users or End-User via the
            Services may include the following categories of data: user IDs,
            email, documents, presentations, images, calendar entries, tasks and
            other data. Data Subjects Personal data submitted, stored, sent or
            received via the Services may concern the following categories of
            data subjects: Users including Data Processor’s employees and
            contractors; Users including Data Processor’s customers, suppliers
            and subcontractors; and any other person who transmits data via the
            Services, including individuals collaborating and communicating with
            Users and End-Users. Appendix 2: Security Measures The Data
            Processor will implement and maintain the Security Standards set out
            in this Appendix 2 to the DPA. the Data Processor may update or
            modify such Security Measures from time to time provided that such
            updates and modifications do not result in the degradation of the
            overall security of the Services. 1. Physical Access Controls: Data
            Processor shall take reasonable measures to prevent physical access,
            such as security personnel and secured buildings and factory
            premises, to prevent unauthorized persons from gaining access to
            Personal Data, or ensure Third Parties operating data centers on its
            behalf are adhering to such controls. 2. System Access Controls Data
            Processor shall take reasonable measures to prevent Personal Data
            from being used without authorization. These controls shall vary
            based on the nature of the Processing undertaken and may include,
            among other controls, authentication via passwords and/or two-factor
            authentication, documented authorization processes, documented
            change management processes and/or, logging of access on several
            levels. 3. Data Access Controls Data Processor shall take reasonable
            measures to provide that Personal Data is accessible and manageable
            only by properly authorized staff, direct database query access is
            restricted and application access rights are established and
            enforced to ensure that persons entitled to use a data processing
            system only have access to the Personal Data to which they have
            privilege of access; and, that Personal Data cannot be read, copied,
            modified or removed without authorization in the course of
            Processing. 4. Transmission Controls: Data Processor shall take
            reasonable measures to ensure that it is possible to check and
            establish to which entities the transfer of Personal Data by means
            of data transmission facilities is envisaged so Service Data cannot
            be read, copied, modified or removed without authorization during
            electronic transmission or transport. 5. Input Controls: Data
            Processor shall take reasonable measures to provide that it is
            possible to check and establish whether and by whom Service Data has
            been entered into data processing systems, modified or removed. Data
            Processor shall take reasonable measures to ensure that (i) the
            Personal Data source is under the control of Data Controller; and
            (ii) Personal Data integrated into the Service is managed by secured
            transmission from Data Controller. 6. Data Backup: Back-ups of the
            databases in the Service are taken on a regular basis, are secured,
            and encrypted to ensure that Personal Data is protected against
            accidental destruction or loss when hosted by Data Processor. 7.
            Logical Separation: Data from different Data Processor’s subscriber
            environments is logically segregated on Data Processor’s systems to
            ensure that Personal Data that is collected for different purposes
            may be Processed separately.
          </div>
        </div>
      </div>
    )
  }
}

const Break = styled.div`
  height: 40px;
`

const LogoHolder = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;
  align-self: center;
`

const Header = styled.div`
  font-size: 40px;
  margin-bottom: 20px;
`

const Body = styled.div`
  margin-top: 5px;
  margin-bottom: 5px;
  font-family: Open Sans;
`

const Section = styled.div`
  font-size: 28px;
  margin-top: 5px;
  margin-bottom: 5px;
`

const TermsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  align-items: flex-start;
`

const OuterContainer = styled.div`
  display: flex;
  min-width: 100vh;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
`
